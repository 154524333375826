<template>
  <div class="bodycont">
    <comHeader />
    <div class="aboutbanner"></div>
    <div class="indexMinker indexMinkerSd" style="padding: 25px 0;">
    <div class="cont w1146">
      <div class="dic_inner">
        <div class="aboutNav dic_flex" style="display:none;">
          <a href="#" :class="[{'on':on==1}]" rel="nofollow" @click="change(1)">关于迪科</a>
          <a href="#" :class="[{'on':on==2}]" rel="nofollow" @click="change(2)">发展历程</a>
          <a href="#" :class="[{'on':on==3}]" rel="nofollow" @click="change(3)">核心技术</a>
          <a href="#" :class="[{'on':on==4}]" rel="nofollow" @click="change(4)">合作伙伴</a>
          <a href="#" :class="[{'on':on==5}]" rel="nofollow" @click="change(5)">招聘英才</a>
          <a href="#" :class="[{'on':on==6}]" rel="nofollow" @click="change(6)">联系我们</a>
        </div>
      <div :class="['contsbox',{'active':on==1}]">
        <div class="atitle"></div>
        <div class="zpp-box">
          <div class="apbox">
           <h3 class="zptitle">销售经理</h3>
                <p>岗位职责:</p>
                <p>1、广泛收集市场信息，形成独立的市场分析。</p>
                <p>2、分析客户需求，协调、组织技术人员进行需求探讨、方案撰写。</p>
                <p>3、按规定完成销售指标，为客户提供主动、热情、满意、周到的服务。</p>
                <p>4、 熟悉公司产品，积极跟进客户和项目，并能进行产品演示和组织商务谈判，实现成交。</p>
                <p>5、负责项目货款及时足额回笼。</p>
                <p>6、参与项目售后的质量跟踪，跟进售后服务完成情况。</p>
                <p>7、 搜集寻找客户资料，建立客户档案。</p>
                <p>8、完成每周每月的工作报告。</p>
                <p>9、完成上级交办的其它任务。</p>
                <p>岗位要求:</p>
                <p>1、大专及以上学历，市场营销或计算机相关专业。</p>
                <p>2、具有5年以上相关软件行业营销经验，并在教育、公安行业有客户积累者优先。</p>
                <p>3、销售意识明确、抗压能力强。</p>
                <p>5、 具有亲和力，性格开朗、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧。</p>
                <p>6、善于挖掘客户，有较强的学习能力、综合分析能力及反馈意识。</p>
                <p>7、有创业梦想。</p>
          </div>
                  <div class="apbox">
           <h3 class="zptitle">前端开发工程师</h3>
<p>职位描述：</p>
<p>1、负责移动端APP功能设计、开发和实现；</p>
<p>2、APP端框架设计及组件开发，编写可复用的框架和组件代码；</p>
<p>3、APP端的缺陷修复、易用性改进和性能优化；</p>
<p>4、按计划迭代产品，并输出程序及开发资料；</p>

<p>技术要求：</p>
<p>1.有APICloud开发经验者优先</p>
<p>2、深入理解和掌握JavaScript，熟练应用HTML5/CSS3/XML/JSON等基本的前端技术要求；</p>
<p>3、熟练运用多个常用的Vue的UI框架；</p>
<p>4、具备3年以上移动端APP、WebApp开发经验,有开发过1个及以上的产品；</p>
<p>5、对Android与iOS不同平台的页面适配及兼容性有较好的了解；</p>
<p>6、具有GIS地图相关开发工作经验；</p>
<p>7、具有良好、规范的代码习惯，喜欢钻研新技术。</p>

          </div>
                            <div class="apbox">
           <h3 class="zptitle">Java开发工程师</h3>
<p>岗位职责：</p>
<p>1、参与系统需求分析、架构设计和概要设计；</p>
<p>2、独立进行系统功能模块的分析设计和核心功能的开发；</p>
<p>3、负责解决开发过程中的技术问题；</p>
<p>4、有能力参与需求分析、软件设计、计划制定与一些技术文档的编写工作；</p>
<p>5、定期向上级主管汇报工作情况。</p>
<p>岗位要求：</p>
<p>1、有扎实的Java开发经验，具有5年及以上Java开发经验，计算机相关专业；</p>
<p>2、具备良好的面向对象编程经验，具有较强的分析设计能力；</p>
<p>3、熟练HTML/CSS/Javascript/JQuery等前端开发；</p>
<p>4、熟练掌握MySQL，Oracle，SQL，存储过程编写等；</p>
<p>5、熟悉SpringMVC等Java框架，熟练掌握Springboot，MyBatis，Hibernate，Netty，Mina优先；</p>
<p>6、有开发系统接口经验；</p>
<p>7、有客户现场开发经验；</p>
<p>8、善于学习与沟通，具有团队精神；</p>


          </div>
        </div>
    </div>
    
    </div>
    </div>
</div>
   <indexFoot />
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import navTitle from '@/components/navtitle.vue'
  import indexFoot from '@/components/indexfoot.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle,
      indexFoot
    },
    data() {
      return {
          on:1
      }
    },
    created() {

    },
    mounted() {

    },
    methods:{
      change(item){
        this.on = item
      }
    }
  }
</script>
<style lang="less" scoped>
.bodycont{
  // background:#f5f7fa;
}
  .aboutbanner{
    width:100%;
    height:300px;
    background: url(../../assets/images/about/about.png) no-repeat center center;
    background-size: cover;
  }
  .aboutNav{
    margin-top:20px;
    justify-content: space-around;
    align-items: center;
    height:44px;
  }
  .contsbox{
    padding:40px 0;
    display: none;
    min-height: 400px;
  }
  .contsbox.active{
    display: block;
  }
  .atitle{
    text-align: center;
    font-size: 28px;
  }
  p{
    line-height: 28px;
    color:#666;
    text-align: justify;
    font-size: 14px;
  }
  .flx_align{
    align-items: center;
    justify-content: space-around;
  }
  .apbox{
    margin: 0 auto;
    margin-bottom:40px;
    padding-left: 300px;
  }
  .zptitle{
    margin-bottom:20px;
  }
  .atitle{
    margin-bottom:40px;
    margin-top:60px;
  }
  .on{
    color: #eb6100 !important;
  }
  .dd{
    width:500px;
    height:286px;
    overflow:hidden;
  }
  .dd img{
    width:100%;
    height:auto;
  }
  .dt{
    margin-top:30px;
    font-size:18px;
  }
  .ds{
    font-size: 16px;
    margin-top:15px;
  }
</style>
